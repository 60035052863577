import * as React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IInvoiceModel } from '../models/IInvoice';


const InvoiceAccountDetailMobile = ({ invoiceAccountDetails }: { invoiceAccountDetails: IInvoiceModel }) => {
  return (
    <div>
      <Accordion className="accordianCard">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="accordianHeader">Account Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="cardInfo" style={{marginBottom:10}}>
            <div className="subHeading">Customer ID: {invoiceAccountDetails.CustomerId}</div>
          </div>
          <div>
            <div className="detail">
              <Typography component={'div'}>
                <p ><b>Ordered By:</b> {invoiceAccountDetails.OrderedBy}</p>
              </Typography>
              <Typography component={'div'}>
                <p><b>Salesperson ID:</b> {invoiceAccountDetails.SalesPersonId}</p>
              </Typography>
            </div>
            <hr style={{border: '1px solid #eeeff1', marginTop:10, marginBottom:10}}/>
            <div className="detail">
              <Typography component={'div'}>
                <p><b>Terms:</b> {invoiceAccountDetails.Terms}</p>
              </Typography>
              <Typography component={'div'}>
                <p><b>Net Due:</b> {invoiceAccountDetails.NetDueDateString}</p>
              </Typography>
              <Typography component={'div'}>
                <p ><b>Phone:</b>  {invoiceAccountDetails.AccountPhone}</p>
              </Typography>
              <Typography component={'div'}>
                <p><b>Fax:</b> {invoiceAccountDetails.AccountFax}</p>
              </Typography>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default InvoiceAccountDetailMobile;