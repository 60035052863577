import * as React from 'react';
import {Card, CardContent} from "@mui/material";
import { IAnnouncements } from '../models/IAnnouncements';

const AnnouncementsDesktop = ({ announcements }: { announcements: IAnnouncements[] }) => {

    return (
        <Card className="acroot-left" variant="outlined">
            <CardContent>
                <p className="cardTitle">Announcements</p>
                <table className="announcementsTable" cellPadding="0" cellSpacing="0">
                    <thead>
                    <tr>
                        <th style={{width:100}}>Date</th>
                        <th style={{width:500}}>Title</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                {announcements.map((item, i) => {
                        return (
                            <tr key={`announcement_${i}`}>
                                <td style={{fontWeight:'bold'}}>{item.Date}</td>
                                <td className="announcementsTableTitle">{item.Title}</td>
                                <td><div dangerouslySetInnerHTML={{__html: item.Body}} style={{textAlign:'left'}} /></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </CardContent>
        </Card>
    );
}
export default AnnouncementsDesktop;