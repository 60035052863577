import * as React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IOrderModel } from '../models/IOrder';


const OrderAccountDetailMobile = ({ orderAccountDetails }: { orderAccountDetails: IOrderModel }) => {
  return (
    <div>
      <Accordion className="accordianCard">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="accordianHeader">Account Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="cardInfo" style={{marginBottom:10}}>
            <div className="subHeading">Customer ID: {orderAccountDetails.CustomerId}</div>
          </div>
          <div>
            <div className="detail">
              <Typography component={'div'}>
                <p ><b>Ordered By:</b> {orderAccountDetails.OrderedBy}</p>
              </Typography>
              <Typography component={'div'}>
                <p ><b>Phone:</b>  {orderAccountDetails.AccountPhone}</p>
              </Typography>
              <Typography component={'div'}>
                <p><b>Fax:</b> {orderAccountDetails.AccountFax}</p>
              </Typography>
            </div>
            <hr style={{border: '1px solid #eeeff1', marginTop:10, marginBottom:10}}/>
            <div className="detail">
              <Typography component={'div'}>
                <p><b>Terms:</b> {orderAccountDetails.Terms}</p>
              </Typography>
              <Typography component={'div'}>
                <p><b>Salesperson:</b> {orderAccountDetails.SalesPersonId}</p>
              </Typography>
              <Typography component={'div'}>
                <p><b>System Engineer:</b> {orderAccountDetails.SystemEngineer}</p>
              </Typography>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default OrderAccountDetailMobile;