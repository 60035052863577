
import { Skeleton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import MediaQuery from 'react-responsive';
import AnnouncementsDesktop from './Component/announcementsDesktop';
import AnnouncementsMobile from './Component/announcementsMobile';
import { IAnnouncements } from './models/IAnnouncements';
import UserService from "./services/UserService";
import './styles/dashboard.css';



const Announcements = () => {

    const [announcementsLoaded, setAnnouncementsLoaded] = useState<boolean>(false);
    const [announcements, setAnnouncements] = useState<IAnnouncements[]>([]);

    const fetchAnnouncements = useCallback(async () => {

        var announcementsResponse = await UserService.GetAnnouncements();

        if (announcementsResponse.success && announcementsResponse.value != null) {
            setAnnouncements(announcementsResponse.value);
            setAnnouncementsLoaded(true);
        }

    }, []);

    useEffect(() => {
        fetchAnnouncements()
    }, [fetchAnnouncements])

    return (
        <>
            <div className="accountprofile">
                
                <div className="modal-body">
                    <MediaQuery maxWidth={1024}>
                        {!announcementsLoaded ?
                            <Skeleton variant="text" height={291} />
                            :
                            <AnnouncementsMobile announcements={announcements} />
                        }
                    </MediaQuery>
                    <MediaQuery minWidth={1025}>
                        {!announcementsLoaded ?
                            <Skeleton variant="text" height={291} />
                            :
                            <AnnouncementsDesktop announcements={announcements} />
                        }
                    </MediaQuery>
                </div>
            </div>
        </>)

}
export default Announcements;


