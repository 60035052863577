import * as React from 'react';
import { CardContent, Button, Card } from "@mui/material";
import MediaQuery from "react-responsive";
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import Header from "../Component/header";

import '../styles/registration.css';

export default function Registration() {

    const { instance } = useMsal();
    
    const login = () => {
        instance.loginRedirect(loginRequest)
    };

    const contactUs = () => {
        window.open('https://www.orenco.com/inquiry', '_blank');
    };

    return (
        <>
        <MediaQuery minWidth={1025}>
            <div style={{marginLeft:300,marginRight:300}}>
                <Header/>
                <div className="blue-banner">
                </div>
                <div className="registration-background" style={{height:500,width:'100%'}}>
                    <Card variant="elevation" style={{width:700,height:300,left:'calc(50% - 350px)',marginTop:100,position:'absolute',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                        <CardContent style={{width:'100%'}}>
                            <div style={{width:'50%',display:'inline-block'}}>
                            </div>
                            <div style={{width:'50%',textAlign:'center',display:'inline-block'}}>
                                <h2>Sign into your account</h2>
                                <Button type="button" onClick={login} style={{backgroundColor:'#002D74',color:'white',width:'75%',marginTop:25,fontWeight:'bold'}}>Login</Button>
                            </div>
                        </CardContent>
                    </Card>
                    <Card variant="elevation" style={{width:400,left:'calc(50% - 400px)',marginTop:125,height:250,position:'absolute',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#002D74',color:'white'}}>
                        <CardContent style={{textAlign:'center'}}>
                            <h2>Need an Account?</h2>
                            <h3>We're working to create smarter, better tools for you and your employees</h3>
                            <Button type="button" onClick={contactUs} style={{backgroundColor:'#002D74',border:'solid 1px lightblue',color:'white',width:'75%',marginTop:25,fontWeight:'bold'}}>
                                Contact Us
                            </Button>
                        </CardContent>
                    </Card>
                </div>
            </div>    
        </MediaQuery> 
        <MediaQuery maxWidth={1024}>
            <Header/>
            <div style={{height:50,backgroundColor:'#002D74'}}>
                &nbsp;
            </div>
            <div style={{display:'flex', flex: '1 1 auto', flexDirection:'column',justifyContent:'center',alignItems:'center', marginLeft:20, marginRight:20}}>
                
                <Card variant="outlined" className={"registration-card"} style={{width:400,height:150,margin:50,display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <CardContent style={{textAlign:'center'}}>
                        <h2>Sign into your account</h2>
                        <Button type="button" onClick={login} style={{backgroundColor:'#002D74',color:'white',width:'75%',marginTop:25,fontWeight:'bold'}}>Login</Button>
                    </CardContent>
                </Card>
                <Card variant="outlined" className={"registration-card"} style={{width:400,height:200,marginBottom:50,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#002D74',color:'white'}}>
                    <CardContent style={{textAlign:'center'}}>
                        <h2>Need an Account?</h2>
                        <h3>We're working to create smarter, better tools for you and your employees</h3>
                        <Button type="button" onClick={contactUs} style={{backgroundColor:'#002D74',border:'solid 1px lightblue',color:'white',width:'75%',marginTop:25,fontWeight:'bold'}}>
                            Contact Us
                        </Button>
                    </CardContent>
                </Card>
            </div>
        </MediaQuery> 
        </>
    )
}