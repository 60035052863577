import React from 'react';
import {Card, CardContent} from "@mui/material";

import '../styles/dashboard.css'

import { IAccountDetailsModel } from '../models/IAccountDetails';

const AccountProfileCard = ({ accountDetails }: { accountDetails: IAccountDetailsModel }) => {
    
    return (
        <>
            <Card className="acroot-left" variant="outlined">
                <CardContent>
                    <p className="cardTitle">Account Profile</p>
                    <div style={{display:'flex'}}>
                        <div className="dataleft">
                            <span>Company Name: <b>{accountDetails.CompanyName}</b></span>
                        </div>
                        <div className="dataright">
                            <span>Account Balance: <b>{accountDetails.AccountBalanceString}</b></span>
                        </div>
                    </div>
                    <div style={{display:'flex',marginTop:40, justifyContent:'space-between'}}>
                        <div style={{display:'flex'}}>
                            <div style={{display:'flex',flexDirection:'column', textAlign:'left',marginRight:50}}>
                                <span className={'accountProfileSubHeader'}>Address</span>
                                <div className={'subText'}>{accountDetails.CompanyAddress?.Street1}</div>
                                <div className={'subText'}>{accountDetails.CompanyAddress?.Street2}</div>
                                <div className={'subText'}>{accountDetails.CompanyAddress?.City}, {accountDetails.CompanyAddress?.State} {accountDetails.CompanyAddress?.ZipCode}</div>
                                <div className={'subText'}>{accountDetails.CompanyAddress?.Country}</div>
                            </div>
                            <div style={{display:'flex',flexDirection:'column', textAlign:'left',paddingLeft:50,borderLeft: 'solid 2px #e9e9e9'}}>
                                <span className={'accountProfileSubHeader'}>Phone</span>
                                <div className={'subText'}><span style={{width:70,display:'inline-block'}}>Primary:</span><span>{accountDetails.CompanyPhone}</span></div>
                                <div className={'subText'}><span style={{width:70,display:'inline-block'}}>Fax:</span><span>{accountDetails.CompanyFax}</span></div>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'column', textAlign:'left'}}>
                            <span className={'accountProfileSubHeader'}>Website</span>
                            <a rel="noreferrer" href={accountDetails.CompanyWebsite} target="_blank">{accountDetails.CompanyWebsite}</a>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card className="acroot-right" variant="outlined">
                <CardContent>
                <p className="cardTitle">Company Contacts</p>
                    {accountDetails.Contacts.map((item, i) => {
                        return (<div key={"contactDetails" + i} className="Seconddataleft">
                            <div style={{color:'var(--orenco-royal-blue)',fontSize:20,marginBottom:10}}>{(item.Name !== null && item.Name !== '' ? item.Name : '')}&nbsp;</div>
                            <div>Job Title:   <b>{item.Role}</b></div>
                            <div>Phone:   <b>{item.Phone}</b></div>
                            <div>Mobile:   <b>{item.Phone}</b></div>
                            <div>Email:   <b>{item.Email}</b></div>
                        </div>)
                    })}

                </CardContent>
            </Card>
            <Card className="acroot-right" variant="outlined">
                <CardContent>
                <p className="cardTitle">Portal Users</p>
                    {accountDetails.PortalUsers.map((item, i) => {
                        return (<div key={"contactName" + i} className="Seconddataleft">
                            <div style={{color:'var(--orenco-royal-blue)',fontSize:20,marginBottom:10}}>{item.FullName}</div>
                            <div className="accountProfileSubHeader">Role: <b><span className="subText">{item.ApplicationRole}</span></b></div>
                        </div>
                        )
                    })}
                </CardContent>
            </Card>
        </>
    );
}
export default AccountProfileCard;