import React from 'react';
import { IOrderProductProps } from '../models/IOrderProductProps';

const ProductsOrderedDesktop = (props: IOrderProductProps) => {
    
    return (

        <>  <table className="order-table" style={{marginBottom:0}}>
                <thead>
                    <tr>
                        <th scope="col">Qty</th>
                        <th scope="col">Item #</th>
                        <th scope="col">Description</th>
                        <th scope="col" style={{textAlign:'right'}}>Price</th>
                        <th scope="col" style={{textAlign:'right'}}>U/M</th>
                        <th scope="col" style={{textAlign:'right'}}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {props.item.OrderDetails.map((item, i) => {                    
                        let key = "tr" + i.toString();
                        return (
                            <tr key={key}>
                                <td>{item.Quantity}</td>
                                <td>{item.ItemNumber}</td>
                                <td>{item.Description}</td>
                                <td style={{textAlign:'right'}}>{item.PriceString}</td>
                                <td style={{textAlign:'right'}}>{item.UM}</td>
                                <td style={{textAlign:'right'}}>{item.TotalString}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <table className="order-table">
                <tbody>
                    <tr style={{ background:'#e9e9e9'}}>
                        <td style={{maxWidth:'99%'}}></td>
                        <td style={{ fontWeight:'bold', width: 150}}>Subtotal</td>
                        <td style={{ fontWeight:'bold', textAlign:'right', width:100}}>{props.item.SubtotalString}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style={{ fontWeight:'bold'}}>Tax</td>
                        <td style={{ fontWeight:'bold', textAlign:'right'}}>{props.item.TaxTotalString}</td>
                    </tr>
                    <tr>
                        <td style={{}}></td>
                        <td style={{ fontWeight:'bold'}}>S & H</td>
                        <td style={{ fontWeight:'bold', textAlign:'right'}}>{props.item.ShippingAndHandlingTotalString}</td>
                    </tr>
                    <tr>
                        <td style={{}}></td>
                        <td style={{ fontWeight:'bold'}}>Trade Discount</td>
                        <td style={{ fontWeight:'bold', textAlign:'right'}}>{props.item.TradeDiscountTotalString}</td>
                    </tr>
                    <tr  style={{ background:'#002d74'}}>
                        <td style={{}}></td>
                        <td style={{ color:'white', fontWeight:'bold', fontSize:18}}>Total <span style={{ color:'white', fontSize:12}}>USD</span></td>
                        <td style={{ color:'white', fontWeight:'bold', fontSize:18, textAlign:'right'}}>{props.item.TotalString}</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default ProductsOrderedDesktop;