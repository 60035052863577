import React from 'react';

import { IOrderModel } from '../models/IOrder';

const OrderAccountDetailDesktop = ({orderAccountDetails}: {orderAccountDetails: IOrderModel}) => {
    
    return (
        <table className="order-table">
            <thead>
                <tr>
                    <th scope="col">Customer ID</th>
                    <th scope="col">Ordered By</th>
                    <th scope="col">Salesperson</th>
                    <th scope="col">System Engineer</th>
                    <th scope="col">Terms</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Fax</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{orderAccountDetails.CustomerId}</td>
                    <td>{orderAccountDetails.OrderedBy}</td>
                    <td>{orderAccountDetails.SalesPersonId}</td>
                    <td>{orderAccountDetails.SystemEngineer}</td>
                    <td>{orderAccountDetails.Terms}</td>
                    <td>{orderAccountDetails.AccountPhone}</td>
                    <td>{orderAccountDetails.AccountFax}</td>
                </tr>                
            </tbody>
        </table>
    )
}

export default OrderAccountDetailDesktop;