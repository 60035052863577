import { Skeleton } from '@mui/material';
import React, { useCallback, useEffect, useState } from "react";
import MediaQuery from 'react-responsive';
import AccountProfileAccordion from './Component/accountProfileAccordian';
import AccountProfileCard from './Component/accountProfileCard';
import { IAccountDetailsModel } from './models/IAccountDetails';
import UserService from "./services/UserService";
import './styles/dashboard.css';


const AccountProfile = () => {

    const [accountDetailsLoaded, setAccountDetailsLoaded] = useState<boolean>(false);
    const [accountDetails, setAccountDetails] = useState<IAccountDetailsModel>({} as IAccountDetailsModel);

    const fetchAccountDetails = useCallback(async () => {

        var accountDetails = await UserService.GetAccountDetails();

        if (accountDetails.success && accountDetails.value != null) {
            setAccountDetails(accountDetails.value);
            setAccountDetailsLoaded(true);
        }

    }, []);

    useEffect(() => {
        fetchAccountDetails()
    }, [fetchAccountDetails])

    return (
        <>
            <div className="accountprofile">
                
                <div className="modal-body">
                    <MediaQuery maxWidth={1024}>
                        <h1>Account Profile</h1>
                    </MediaQuery>
                    <MediaQuery maxWidth={1024}>
                        {!accountDetailsLoaded ?
                            <Skeleton variant="text" height={291} />
                            :
                            <AccountProfileAccordion accountDetails={accountDetails} />
                        }
                    </MediaQuery>
                    <MediaQuery minWidth={1025}>
                        {!accountDetailsLoaded ?
                            <Skeleton variant="text" height={291} />
                            :
                            <AccountProfileCard accountDetails={accountDetails} />
                        }
                    </MediaQuery>
                </div>
            </div>
        </>)

}
export default AccountProfile;


