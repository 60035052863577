import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import * as React from 'react';
import { IAccountDetailsModel } from '../models/IAccountDetails';
import styles from '../styles/sharedStyles.module.css';


const AccountProfileAccordion = ({ accountDetails }: { accountDetails: IAccountDetailsModel }) => {

    return (
        <div>
            <Accordion className="accordianOrder acc-infomobile">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className="accordianHeader">Account Information</Typography>
                </AccordionSummary>
                <AccordionDetails className="orderdetail">
                    <div className="cardInfo">
                        <span className="subHeading">Company Name</span>
                        <div className={styles.label} style={{fontWeight:'bold'}}>{accountDetails.CompanyName}</div>
                    </div>
                    <div className="cardInfo">
                        <span className="subHeading">Primary Contact</span>
                        <div className={styles.label} style={{fontWeight:'bold'}}>{accountDetails.PrimaryContact.Name}</div>
                    </div>
                    <hr style={{border: '1px solid #eeeff1'}}/>
                    <div className="cardInfo">
                        <span className="subHeading">Account Balance:</span>
                        <div className={styles.label} style={{fontWeight:'bold'}}>{accountDetails.AccountBalanceString}</div>
                    </div>
                    <div className="cardInfo">
                        <span className="subHeading">Address</span>
                        <div className={'subText'}>{accountDetails.CompanyAddress?.Street1}</div>
                        <div className={'subText'}>{accountDetails.CompanyAddress?.Street2}</div>
                        <div className={'subText'}>{accountDetails.CompanyAddress?.City}, {accountDetails.CompanyAddress?.State} {accountDetails.CompanyAddress?.ZipCode}</div>
                        <div className={'subText'}>{accountDetails.CompanyAddress?.Country}</div>
                    </div>
                    <div className="cardInfo">
                        <span className="subHeading">Phone</span>
                        <div className={'subText'}><span style={{ width: 70, display: 'inline-block' }}>Primary:</span><span>{accountDetails.CompanyPhone}</span></div>
                        <div className={'subText'}><span style={{ width: 70, display: 'inline-block' }}>Fax:</span><span>{accountDetails.CompanyFax}</span></div>
                        <div className={'subText'}><span style={{ width: 70, display: 'inline-block' }}>Website:</span><a rel="noreferrer" href={accountDetails.CompanyWebsite} target="_blank">{accountDetails.CompanyWebsite}</a></div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion className="accordianOrder acc-infomobile">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className="accordianHeader">Company Contacts</Typography>
                </AccordionSummary>
                <AccordionDetails className="orderdetail">
                    <Typography component={'div'}>
                        {accountDetails.Contacts.map((item, i) => {
                            return (
                                <div key={"accountInfo" + i} className="box">
                                    <div className="accinfo-container">
                                        <div className="accountProfileSubHeader" style={{color:'var(--orenco-royal-blue)', fontSize:20}}>
                                            {item.Name}
                                        </div>
                                        <div className="left-d">
                                            <span className="accountProfileSubHeader">Title: </span><span className="subText">{item.Role}</span>
                                        </div>
                                        <div className="left-d">
                                            <span className="accountProfileSubHeader">Email: </span><span className="subText">{item.Email}</span>
                                        </div>
                                        <div className="left-d">
                                            <span className="accountProfileSubHeader">Phone: </span><span className="subText">{item.Phone}</span>
                                        </div>
                                        <div className="left-d">
                                            <span className="accountProfileSubHeader">Mobile: </span><span className="subText">{item.Mobile}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordianOrder acc-infomobile">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className="accordianHeader">Portal Users</Typography>
                </AccordionSummary>
                <AccordionDetails className="orderdetail">
                    <Typography component={'div'}>
                        {accountDetails.PortalUsers.map((item, i) => {
                            return (
                                <div key={"accountInfo" + i} className="box">
                                    <div className="accinfo-container">
                                        <div className="accountProfileSubHeader" style={{color:'var(--orenco-royal-blue)', fontSize:20}}>
                                            {item.FullName}
                                        </div>
                                        <div className="left-d">
                                            <span className="accountProfileSubHeader">Role: </span><span className="subText">{item.ApplicationRole}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Typography>
                    
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
export default AccountProfileAccordion;
