import React, { Component } from 'react';
import logo from '../images/Orenco_Corporate_Logo.svg';

class Footer extends Component {
    render() {
        return (
            <>
                <div className="blue-banner noPrint">
                </div>
                <div className="footer-wrapper noPrint">
                    <div className="container">
                        <div className="company-box">
                            <div className="logo-wrapper" style={{paddingTop:0}}>
                                <div className="logo">
                                    <a href="/">
                                        <img src={logo} alt="Site Logo" />
                                    </a>
                                </div>
                            </div>
                            <div className="footerdetail footerFont">
                                <p>Since 1981, Orenco Systems, Inc. has researched, designed, and manufactured innovative onsite and decentralized wastewater collection and treatment technologies. We also manufacture high-quality standard, custom, and OEM controls, along with state-of-the-art fiberglass buildings, tanks, and enclosures.</p>
                            </div>
                        </div>
                        <div className="linkbox" style={{display:'flex',justifyContent:'center', minWidth:300}}>
                            <div>
                                <h4>Useful links</h4>
                                <ul>
                                    <li><a href="https://www.orenco.com/products" rel="noreferrer" target="_blank" >Product Catalogs</a></li>
                                    <li><a href="https://www.orenco.com/resources/design-aids" rel="noreferrer" target="_blank" >Design Aids</a></li>
                                    <li><a href="https://www.orenco.com/training" rel="noreferrer" target="_blank" >Training &amp; Webinars</a></li>
                                    <li><a href="https://www.orenco.com/dealer-portal-terms-of-use" rel="noreferrer" target="_blank" >Terms &amp; Conditions</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="connectedbox" style={{display:'flex',justifyContent:'flex-end'}}>
                            <div>
                                <h4>Get Connected!</h4>
                                <a rel="noreferrer" href="https://visitor.r20.constantcontact.com/manage/optin?v=001UYKiRe5OVrnsi4KzrG-onkHi5MGBMHpeUCdFqIzmsJavxmNJQgEd0mZS6_Ux-B6hpUiw85dGx4Q0TD50BWQ71-U4CNfp8epuyqHuikV3aRRzYuwLmMOdtFlYzD1H5PQQLRL1qMki_mZMppojAfGZS9lChBvwS9zh" target="_blank">
                                    <div className="sign-up">
                                        <img src="/sign-up.jpg" alt="Footer signup" style={{width:'100%'}} />
                                    </div>
                                </a>
                                <p style={{textAlign:'left'}} className="footerFont">Sign up now and become one of the first to know about new products, upcoming trainings, and industry news.</p>
                            </div>
                        </div>
                    </div>
                    <div className="copyrights">
                        <p>Copyright 2021 by Orenco® Systems Inc.</p>
                    </div>
                </div>
            </>)
    }
}
export default Footer;

