import { SelectChangeEvent } from '@mui/material';
import React, {useState, useCallback, useEffect} from 'react';
import MediaQuery from 'react-responsive'

import {IOrdersModel} from "../models/IOrders";
import OrderService from '../services/OrderService';
import OrdersDesktop from './ordersDesktop';
import OrdersMobile from './ordersMobile';

export interface IOrdersProps {
    orders: readonly IOrdersModel[],
    ordersLoaded: boolean,
    dateRange: number,
    searchText: string,
    searchOnChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
    dateRangeOnChangeHandler: (event: SelectChangeEvent<number>, child: React.ReactNode) => void,
    sortOnChangeHandler: (sortId: number) => void
}

export default function Orders() {
    
    
    const handleDateRangeChange = (event) => {
        setOrdersLoaded(false);
        setDateRange(event.target.value);
    };

    const handleSortChange = (sortId: number) => {
        /*setOrdersLoaded(false);
        setDateRange(event.target.value);*/
    };

    const handleSearchChange = (event) => {
        var searchText = event.target.value;
        setSearchText(searchText);
    };

    const handleSearch = (ordersToSearch: IOrdersModel[]) => {
        if (searchText === '')
        {
            return ordersToSearch;
        }
        else
        {
            var sText = searchText.toLowerCase();
            return ordersToSearch.filter((value) => {
                if (value.InvoiceNumber.toLowerCase().includes(sText)
                || value.OrderDateString.toLowerCase().includes(sText)
                || value.OrderNumber.toLowerCase().includes(sText)
                || value.PONumber.toLowerCase().includes(sText)
                || value.OrderTotalString.toLowerCase().includes(sText)
                || value.ShippingMethod.toLowerCase().includes(sText)
                || value.ShippingStatus.toLowerCase().includes(sText))
                {
                    return true;
                }
            });
        }
    }

    const [dateRange, setDateRange] = React.useState(3);
    const [searchText, setSearchText] = useState<string>('');
    const [ordersLoaded, setOrdersLoaded] = useState<boolean>(false);
    const [orders, setOrders] = useState<IOrdersModel[]>([]);
    const [origOrders, setOrigOrders] = useState<IOrdersModel[]>([]);
    
    const fetchOrders = useCallback(async () => {
    
        var ordersResponse = await OrderService.GetOrders(dateRange);
        
        if (ordersResponse.success && ordersResponse.value != null)
        {
            setOrigOrders(ordersResponse.value);
            setOrders(handleSearch(ordersResponse.value));
            setOrdersLoaded(true);
        }
        
      }, [dateRange]);
    
      useEffect(() => {
          
        fetchOrders()
      }, [fetchOrders])

      useEffect(() => {
          
        setOrders(handleSearch(origOrders));
      }, [searchText])

    return (
        <>
            <MediaQuery minWidth={1025}>
                <OrdersDesktop
                    orders={orders}
                    ordersLoaded={ordersLoaded}
                    dateRange={dateRange}
                    searchText={searchText}
                    sortOnChangeHandler={handleSortChange}
                    searchOnChangeHandler={handleSearchChange}
                    dateRangeOnChangeHandler={handleDateRangeChange}
                />
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <OrdersMobile
                    orders={orders}
                    ordersLoaded={ordersLoaded}
                    dateRange={dateRange}
                    searchText={searchText}
                    sortOnChangeHandler={handleSortChange}
                    searchOnChangeHandler={handleSearchChange}
                    dateRangeOnChangeHandler={handleDateRangeChange}
                />
            </MediaQuery>
        </>
    );
}