import React from 'react';
import { IAccountDetailsModel } from "../models/IAccountDetails";
import styles from '../styles/sharedStyles.module.css';
import '../styles/dashboard.css';

const AccountInfo = ({accountDetails}: {accountDetails: IAccountDetailsModel}) => {
  
  let primaryHref = `mailto:${accountDetails.PrimaryContact.Email}`;

  return (
    <div style={{marginTop:0,marginBottom:10}}>
      <div className="cardInfo">
        <span className="subHeading">Company Name</span>
        <div className={styles.label} style={{fontWeight:'bold'}}>{accountDetails.CompanyName}</div>
      </div>
      <div className="cardInfo">
        <span className="subHeading">Account Balance</span>
        <div className={styles.label} style={{fontWeight:'bold'}}>{accountDetails.AccountBalanceString}</div>
      </div>
      <div className="cardInfo">
        <span className="subHeading">Primary Contact</span>
        <div className={styles.label} style={{fontWeight:'bold'}}>{accountDetails.PrimaryContact.Name}</div>
        <div className={styles.label}>{accountDetails.PrimaryContact.Role}</div>
        <div className={styles.label}>{accountDetails.PrimaryContact.Phone}</div>
        <div className={`${styles.label} ${styles.link}`}><a href={primaryHref}>{accountDetails.PrimaryContact.Email}</a></div>
      </div>
    </div>
  );
}

export default AccountInfo;