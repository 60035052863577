//import {ERROR_STATE} from "../layout/GlobalErrorController";
import { Cache } from "aws-amplify";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import * as jsonToUrl from "json-to-url";
import { msalConfig } from "../authConfig";
import { Result } from "../models/Result";
//import Cookies from 'universal-cookie';

export interface IRequestOptions {
  url: string;
  data?: any;
  method: "GET" | "POST" | "PUT" | "DELETE";
}

export interface ISendFormDataOptions {
  url: string;
  data: FormData;
  method: "POST" | "PUT" | "PATCH";
}

/**
 * Represents base class of the isomorphic service.
 */
export abstract class ServiceBase {

  private static sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * Make request with JSON data.
   * @param opts
   */
  public static async requestJson<T>(opts: IRequestOptions) : Promise<Result<T>> {

    let axiosResponse: any;

    var result: Result<T> | null = null;

    var processQuery = (url: string, data: any): string => {
      if (data) {
        return `${url}?${jsonToUrl(data)}`;
      }
      return url;
    };

    // var token = await ServiceBase.getTokenWithRetry();
    
    const headers = { 
       Authorization: `Bearer ${Cache.getItem("accessToken")}`,
       'Content-Type': 'application/json; charset=utf-8'
    //  Host: window.Configs.HOST
     };

    Axios.defaults.baseURL = msalConfig.auth.apiurl;
    
    var axiosRequestConfig: AxiosRequestConfig;
    axiosRequestConfig = {
      headers: headers
    };

    /*const onResponse = (response: AxiosResponse): AxiosResponse => {
      console.info(`[response] [${JSON.stringify(response)}]`);
      return response;
    }
    
    const onResponseError = (error: AxiosError): Promise<AxiosError> => {
        console.error(`[response error] [${JSON.stringify(error)}]`);
        return Promise.reject(error);
    }
    
    
    Axios.interceptors.response.use(onResponse, onResponseError);
    */
    /*Axios.interceptors.response.use(undefined, err => {
      const error = err.response;
      // if error is 401 
      if (error?.status===401) {
        window.location.reload();
      }
      return err;
    });*/
    
    try {
      switch (opts.method) {
        case "GET":
          axiosResponse = await Axios.get(processQuery(opts.url, opts.data), axiosRequestConfig);
          break;
        case "POST":
          axiosResponse = await Axios.post(opts.url, opts.data, axiosRequestConfig);
          break;
        case "PUT":
          axiosResponse = await Axios.put(opts.url, opts.data, axiosRequestConfig);
          break;
        case "DELETE":
          axiosResponse = await Axios.delete(processQuery(opts.url, opts.data), axiosRequestConfig);
          break;
      }

      if (!axiosResponse)
        throw new Error('No Result');

      result = new Result<T>(axiosResponse.data as T, true, null);

    } catch (error: any) {

      console.log(error);
      result = new Result<T>(null,false, null);

      if (error?.response?.status ===401) {
        window.location.reload();
      }

      if (error?.response?.status ===500) {
        
        if (error?.response?.data?.ExceptionMessage) {
          result = new Result<T>(null, false, [{ code: '', target: 'method_error', message:error?.response?.data?.ExceptionMessage }]);
        }
        else
          result = new Result<T>(null, false, [{ code: '', target: 'method_error', message: 'An unknown error has occurred.'}]);
      }
    }

    return result;
  }

  private static dispatchGlobalError(message: string) {
    console.log('error: ' + message);
    /*Cache.setItem(ERROR_STATE, { title: 'Error', description: message});
    Hub.dispatch(
      'ErrorChannel',
      {
        event: 'errorOccurred',
        data: {hasError:true},
        message:''
      });*/
  }

  /**
   * Allows you to send files to the server.
   * @param opts
   */
  public static async sendFormData<T>(opts: ISendFormDataOptions): Promise<Result<T>> {
    let axiosResponse: AxiosResponse | null = null;

    var result: Result<T> | null = null;
    
    // var token = await ServiceBase.getTokenWithRetry();

    const headers = { 'Content-Type': 'multipart/form-data' };

    var axiosOpts = {
      headers: headers
    };

    try {
      switch (opts.method) {
        case "POST":
          axiosResponse = await Axios.post(opts.url, opts.data, axiosOpts);
          break;
        case "PUT":
          axiosResponse = await Axios.put(opts.url, opts.data, axiosOpts);
          break;
      }

      if (!axiosResponse)
        throw new Error('No Result');

      result = new Result<T>(axiosResponse.data as T, true, null);
    } catch (error: any) {

      console.log(error);

      // parse the server's error if one was provided
      if (error.response) {

        // validation error
        if (error.response.status === 400) {
          if (!Array.isArray(error.response.data.error.details))
            result = new Result<T>(null, false,[{ code: '', target: 'method_error', message: 'One or more validation errors have occurred but we were unable to parse them. Please inspect the console for more information.'}]);
          else
            result = new Result<T>(null, false, error.response.data.error.details);
        }
        else if (error.response.status === 404) {
          ServiceBase.dispatchGlobalError("Method not found!");
          result = new Result<T>(null, false,null);
        }
        else {
          let message = (error.title ? error.title : error.mesage);
          ServiceBase.dispatchGlobalError(message);
          result = new Result<T>(null, false,null);
        }
      }
      else {
        ServiceBase.dispatchGlobalError(error.message);
        result = new Result<T>(null,false, null);
      }
    }

    return result;
  }
}