import { Skeleton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import MediaQuery from 'react-responsive';
import CategoryBar from './Component/categoryBar';
import CustomerServiceAccountInfoCardsDesktop from "./Component/customerServiceAccountInfoCardsDesktop";
import CustomerServiceAccountInfoCardsMobile from "./Component/customerServiceAccountInfoCardsMobile";
import Orders from './Component/orders';
import { IAccountDetailsModel } from "./models/IAccountDetails";
import UserService from "./services/UserService";
import './styles/dashboard.css';

const Dashboard = () => {

    const [accountDetailsLoaded, setAccountDetailsLoaded] = useState<boolean>(false);
    const [accountDetails, setAccountDetails] = useState<IAccountDetailsModel>({} as IAccountDetailsModel);

    const fetchAccountDetails = useCallback(async () => {

        var accountDetails = await UserService.GetAccountDetails();

        if (accountDetails.success && accountDetails.value != null) {
            setAccountDetails(accountDetails.value);
            setAccountDetailsLoaded(true);
        }

    }, []);

    useEffect(() => {
        fetchAccountDetails()
    }, [fetchAccountDetails])

    return (
        <>
            <div className="modal-body">
                <h1>Orenco Customer Portal</h1>
                <CategoryBar />
                <MediaQuery minWidth={1025}>
                    {!accountDetailsLoaded ?
                        <Skeleton variant="text" height={291} />
                        :
                        <CustomerServiceAccountInfoCardsDesktop accountDetails={accountDetails} />
                    }
                </MediaQuery>
                <MediaQuery maxWidth={1024}>
                    {!accountDetailsLoaded ?
                        <Skeleton variant="text" height={291} />
                        :
                        <CustomerServiceAccountInfoCardsMobile accountDetails={accountDetails} />
                    }
                </MediaQuery>
                <Orders/>
            </div></>)

}
export default Dashboard;


