import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export interface ISortChooserProps {
    open: boolean,
    onClose: (selectedValue: number) => void,
    selectedValue: number
}

const SortChooser = (props: ISortChooserProps) => {
    
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const sortOptions = [
      { label: 'Order Date - Oldest to Newest', id: 1},
      { label: 'Order Date - Newest to Oldest', id: 2},
      { label: 'Order Number - A to Z', id: 3},
      { label: 'Order Number - Z to A', id: 4},
      { label: 'Invoice Number - A to Z', id: 5},
      { label: 'Invoice Number - Z to A', id: 6}];

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Choose Sort Order</DialogTitle>
      <List sx={{ pt: 0 }}>
        {sortOptions.map((item) => (
          <ListItem button onClick={() => handleListItemClick(item.id)} key={item.id}>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default SortChooser;