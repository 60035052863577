import { AlertTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { ISuperUserAccount } from "../models/IAccountDetails";
import UserService from "../services/UserService";
import { SidebarData } from "./sideMenuData";

export default function ChangeAccountModal({ userRole }) {
  const [open, setOpen] = React.useState(false);
  const [accountId, setAccountId] = React.useState("");
  const [validAccountId, setValidity] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const [validAccounts, setValidAccounts] = React.useState([]);
  const [superUserAccounts, setSuperUserAccounts] = React.useState<ISuperUserAccount[]>([]);

  const fetchAccounts = useCallback(async () => {
    var accountsResponse = await UserService.GetSuperUserAccounts();

    if (accountsResponse.success && accountsResponse.value != null) {
      setSuperUserAccounts(accountsResponse.value);
    }
  }, []);

  useEffect(() => {
    if (userRole === "SuperUser") fetchAccounts();
  }, [fetchAccounts]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setError("");
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountId(e.target.value);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>, child: React.ReactNode) => {
    console.log(e.target);
    setAccountId(e.target.value);
  };

  const validateAccountId = () => {
    setValidity(accountId.length === 10);
  };

  const handleSave = () => {
    if (validAccountId) {
      changeAccount();
    }
  };

  const changeAccount = async() => {
    setError("");
    setLoading(true);
    var changeAccountResponse = await UserService.ChangeAccountID(accountId);

    setLoading(false);
    if (changeAccountResponse.success) {
        setOpen(false);
        window.location.reload();
    } else if (changeAccountResponse.hasErrors) {
        raiseError(changeAccountResponse.errors[0].message);

        console.log(changeAccountResponse);
    }
  };

  const raiseError = (error: string) => {
    console.log(error);
    setError(error);
  };

  return (
    <li key={SidebarData.length} className="nav-text">
      <a onClick={handleClickOpen}>Change Account ID</a>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Account ID</DialogTitle>
        <DialogContent>
          {userRole === "SuperAdmin" && (
            <DialogContentText>
              To change what account you see, please enter the account ID of the account you would like to change to.
            </DialogContentText>
          )}
          {userRole === "SuperUser" && <DialogContentText>Select an account from the dropdown below and click 'Save'.</DialogContentText>}
          {error !== "" && (
            <Alert severity="error" style={{marginTop:10,marginBottom:10}}>
              <AlertTitle>{error}</AlertTitle>
            </Alert>
          )}
          {userRole === "SuperAdmin" && (
            <TextField
              autoFocus
              margin="dense"
              id="accountId"
              label="Account ID"
              type="text"
              fullWidth
              variant="standard"
              onBlur={validateAccountId}
              helperText={validAccountId ? "" : "Account ID must be 10 characters."}
              onChange={handleChange}
            />
          )}
          {userRole === "SuperUser" && (
            <>
              <FormControl fullWidth  style={{marginTop:20}}>
                <InputLabel id="account-label">Account</InputLabel>
                <Select labelId="account-label" id="selAccount" label="Account" value={accountId} onChange={handleSelectChange}>
                  {superUserAccounts.map((item: ISuperUserAccount, idx: number) => {
                    return (
                      <MenuItem key={`m${idx}`} value={item.AccountID}>
                        {item.Description} - {item.AccountID}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </DialogActions>
        )}
      </Dialog>
    </li>
  );
}
