export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/',
    cName: 'nav-text',
    isQuickLink: false,
  },
  {
    title: 'Account Profile',
    path: '/AccountProfile',
    cName: 'nav-text',
    isQuickLink: false,
  },
  {
    title: 'Announcements',
    path: '/Announcements',
    cName: 'nav-text',
    isQuickLink: false,
  },
  {
    title: 'Design Aids',
    path: 'https://www.orenco.com/design-aids',
    cName: 'nav-text',
    isQuickLink: true,
    isRedirect: true,
  },
  {
    title: 'Training & Webinars',
    path: 'https://www.orenco.com/training',
    cName: 'nav-text',
    isQuickLink: true,
    isRedirect: true,
  },
  {
    title: 'Documents',
    path: 'https://www.orenco.com/resources/document-library',
    cName: 'nav-text',
    isQuickLink: true,
    isRedirect: true,
  },
  {
    title: 'Podcasts',
    path: 'https://www.orenco.com/resources/podcasts',
    cName: 'nav-text',
    isQuickLink: true,
    isRedirect: true,
  },
  {
    title: 'Videos',
    path: 'https://www.orenco.com/resources/videos',
    cName: 'nav-text',
    isQuickLink: true,
    isRedirect: true,
  },
  {
    title: 'Product Catalog',
    path: 'https://www.orenco.com/products',
    cName: 'nav-text',
    isQuickLink: true,
    isRedirect: true,
  },
];