import { useCallback, useEffect, useState } from "react";
import { CardContent, Typography, Card } from "@mui/material";
import MediaQuery from 'react-responsive';
import { useParams, RouteComponentProps } from "react-router-dom";

import OrderService from '../services/OrderService';
import { IOrderModel } from '../models/IOrder';

import '../styles/invoice-order.css';
import ProductsOrderedDesktop from "./productsOrderedDesktop";
import OrderDetailDesktop from "./orderDetailDesktop";
import OrderDetailMobile from "./orderDetailMobile";
import OrderAccountDetailDesktop from "./orderAccountDetailDesktop";
import { CircularProgress } from "@mui/material";

import logo from '../images/Orenco_Corporate_Logo.svg';

import PrintIcon from '@mui/icons-material/Print';
import OrderAccountDetailMobile from "./orderAccountDetailMobile";

export default function Order(props: RouteComponentProps<{
    orderNumber: string;
}>) {
    
    let { orderNumber } = useParams<{ orderNumber: any }>();

    const [orderLoaded, setOrderLoaded] = useState<boolean>(false);
    const [order, setOrder] = useState<IOrderModel>({} as IOrderModel);//useState<IOrderModel[]>([]); 


    const fetchOrder = useCallback(async () => {

        var orderResponse = await OrderService.GetOrder(orderNumber);
        if (orderResponse.success && orderResponse.value != null) {
            
            setOrder(orderResponse.value);
            setOrderLoaded(true);
        }

    }, [orderNumber]);

    useEffect(() => {

        fetchOrder()
    }, [fetchOrder])

    const handlePrint = () => {
        window.print();
    };

    return (
        <>
            {!orderLoaded ?
                <div className="orderLoading" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
                :
                <> <div className="modal-body order-body">
                        <Card variant="outlined">
                            <CardContent>
                                <MediaQuery minWidth={1025}>
                                    <Typography className="order-title-container printOnly" component={'div'}>
                                        <div className="order-container-logo">
                                            <a href="/">
                                                <img src={logo} alt="Site Logo" className="order-top-logo" />
                                            </a>
                                        </div>
                                        <span className="title">Order</span>
                                    </Typography>
                                    <Typography component={'div'}>
                                        <div className="subtitle-container">
                                            <div className="subtitle"><span className="bold">Date:&nbsp;</span>{order?.OrderDateString}</div>
                                            <div className="subtitle"><span className="bold">Order:&nbsp;</span>{order?.OrderNumber}</div>
                                        </div>
                                    </Typography>
                                </MediaQuery>
                                <MediaQuery maxWidth={1024}>
                                    <div className="subtitle-container">
                                        <div className="subtitle-text"><h4>Date:&nbsp;</h4><h4>{order?.OrderDateString}</h4></div>
                                        <div className="subtitle-text"><h3>Order:&nbsp;</h3><h3>{order?.OrderNumber}</h3></div>
                                    </div>
                                </MediaQuery>
                                <Typography component={'div'}>
                                    <div className="info-container">
                                        <div className="info">
                                            <span className="bold">Bill To:</span>
                                            <span className="cname">{order.BillingCompanyName}</span>
                                            <span>{order.BillingAddress?.Street1}</span>
                                            <span>{order.BillingAddress?.Street2}</span>
                                            <span>{order.BillingAddress?.City}, {order.BillingAddress?.State}, {order.BillingAddress?.ZipCode}</span>
                                            <span>{order.BillingAddress?.Country}</span>
                                        </div>
                                        <hr style={{border: '1px solid #eeeff1', marginTop:10, marginBottom:10}}/>
                                        <div className="info">
                                            <span className="bold">Ship To:</span>
                                            <span className="cname">{order.ShippingCompanyName}</span>
                                            <span>{order.ShippingAddress?.Street1}</span>
                                            <span>{order.ShippingAddress?.Street2}</span>
                                            <span>{order.ShippingAddress?.City}, {order.ShippingAddress?.State}, {order.ShippingAddress?.ZipCode}</span>
                                            <span>{order.ShippingAddress?.Country}</span>
                                        </div>
                                    </div>
                                </Typography>
                                <MediaQuery minWidth={1025}>
                                    <div className="table-container">
                                        <span className="bold">Account Details</span>
                                    </div>
                                    {orderLoaded && <OrderAccountDetailDesktop orderAccountDetails={order} />}
                                    <div className="table-container">
                                        <span className="bold">Order Details</span>
                                    </div>
                                    {orderLoaded && <OrderDetailDesktop orderDetails={order} />}
                                    <div className="table-container">
                                        <span className="bold">Products Ordered</span>
                                    </div>
                                    {orderLoaded && <ProductsOrderedDesktop item={order} />}
                                </MediaQuery>
                                <MediaQuery maxWidth={1024}>
                                    <div className="acctdetail" style={{ marginTop: 20 }}>
                                        <OrderAccountDetailMobile orderAccountDetails={order} />
                                    </div>
                                    <div className="acctdetail">
                                        <OrderDetailMobile orderDetails={order} />
                                    </div>
                                    <Typography component={'div'}>
                                        <div className="product-container">
                                            {order.OrderDetails && order.OrderDetails.map((item, i) => {
                                                return (
                                                    <div key={"orderDetails" + i.toString()} style={{ borderBottom: '1px solid #37414e' }}>
                                                        <div className="description-container">
                                                            <div className="info">
                                                                <span className="bold blue">Description:</span>
                                                                <span className="semi-bold">Item # {item.ItemNumber} | QTY {item.Quantity}</span>
                                                                <span className="product">{item.Description}</span>
                                                            </div>
                                                            <div className="info" style={{ alignItems: 'flex-end', marginRight:0 }}>
                                                                <span className="bold blue">U/M</span>
                                                                <span className="semi-bold">{item.UM}</span>
                                                                <span className="semi-bold">{item.PriceString}</span>
                                                            </div>
                                                        </div><span className="bold blue total">Line Total: {item.TotalString}</span>
                                                    </div>
                                                )
                                            })}
                                            <div className="bill-container">
                                                <div className="info" style={{ marginRight: 10, width:140 }}>
                                                    <span className="data">Subtotal</span>
                                                    <span className="data">Tax</span>
                                                    <span className="data">S & H</span>
                                                    <span className="data">Trade Discount</span>
                                                </div>
                                                <div className="info" style={{ alignItems: 'flex-end' }}>
                                                    <span className="value">{order.SubtotalString}</span>
                                                    <span className="value">{order.TaxTotalString}</span>
                                                    <span className="value">{order.ShippingAndHandlingTotalString}</span>
                                                    <span className="value">{order.TradeDiscountTotalString}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-bill-container">
                                            <div className="total-container">
                                            <div className="info" style={{ marginRight: 10, width:140 }}>
                                                    <span className="value">Total USD</span>
                                                </div>
                                                <div className="info" style={{ alignItems: 'flex-end'}}>
                                                    <span className="value">{order.TotalString}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Typography>
                                </MediaQuery>
                                <MediaQuery minWidth={1025}>
                                    <div className="buttons">
                                        <div className="print noPrint" style={{width:100}} onClick={handlePrint}><PrintIcon/>PRINT</div>
                                    </div>
                                </MediaQuery>
                            </CardContent>
                        </Card>
                    </div></>
            }
        </>
    );
}