import { ServiceBase } from "./ServiceBase";
import {Result} from "../models/Result";
import {IAccountDetailsModel, ISuperUserAccount, IPortalUser} from "../models/IAccountDetails";
import {IAnnouncements} from "../models/IAnnouncements";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class UserService extends ServiceBase {

    public static async GetCurrentUser(): Promise<Result<IPortalUser>> {

        var result = await this.requestJson<IPortalUser>({
            url: `/Main/GetCurrentUser`,
            method: "GET"
        });

        return result;
    }

    public static async LogoutUser() {

        await this.requestJson({
            url: `/Main/LogoutUser`,
            method: "GET"
        });
        
    }

    public static async GetAccountDetails(): Promise<Result<IAccountDetailsModel>> {

        var result = await this.requestJson<IAccountDetailsModel>({
            url: `/Main/GetAccountDetails`,
            method: "GET"
        });

        return result;
    }

    public static async GetAnnouncements(): Promise<Result<IAnnouncements[]>> {

        var result = await this.requestJson<IAnnouncements[]>({
            url: `/Main/GetAnnouncements`,
            method: "GET"
        });

        return result;
    }

    public static async ChangeAccountID(accountId: string): Promise<Result<boolean>> {
        var result = await this.requestJson<boolean>({
            url: `/Main/ChangeAccountID`,
            data: `"${accountId}"`,
            method: "POST"
        });

        if(result.value === false) {
            throw new Error("Failed to change account ID.")
        }

        return result;
    }

    public static async GetSuperUserAccounts(): Promise<Result<ISuperUserAccount[]>> {
        var result = await this.requestJson<ISuperUserAccount[]>({
            url: `/Main/GetSuperUserAccounts`,
            method: "GET"
        });

        return result;
    }
}