import React from 'react';
import { IInvoiceModel } from '../models/IInvoice';

const InvoiceDetailDesktop = ({invoiceDetails}: {invoiceDetails: IInvoiceModel}) => {  

    return (
        <table className="order-table">
            <thead>
                <tr>
                    <th scope="col">Ship Date</th>
                    <th scope="col">Tracking Number</th>
                    <th scope="col">Shipping Method</th>
                    <th scope="col">PO #</th>
                    <th scope="col">Order #</th>
                    <th scope="col">Project ID</th>
                    <th scope="col">Master #</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{invoiceDetails.ShipDateString}</td>
                    <td>{invoiceDetails.TrackingNumberURL.length > 0 ? <a target='_blank' rel="noreferrer" href={`${invoiceDetails.TrackingNumberURL}`}>{invoiceDetails.TrackingNumber}</a>:<span>{invoiceDetails.TrackingNumber}</span>}</td>
                    <td>{invoiceDetails.ShippingMethod}</td>
                    <td>{invoiceDetails.PONumber}</td>
                    <td>{invoiceDetails.OrderNumber}</td>
                    <td>{invoiceDetails.ProjectID}</td>
                    <td>{invoiceDetails.MasterNumber}</td>
                </tr>                
            </tbody>
        </table>
    )
}

export default InvoiceDetailDesktop;