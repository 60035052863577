import * as React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IOrderModel } from '../models/IOrder';

const OrderDetailMobile = ({orderDetails}: {orderDetails: IOrderModel}) => {  
  return (
    <div>
      <Accordion className="accordianCard">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="accordianHeader">Order Details</Typography>
        </AccordionSummary>
        <AccordionDetails>

        <div className="cardInfo">
          <div className="subHeading">Order #: {orderDetails.OrderNumber}</div>
        </div>
        
        <div>
        <div className="detail">
          <Typography component={'div'} style={{marginTop:10}}>
              <p>Project Id: {orderDetails.ProjectID}</p>
          </Typography>
          <Typography component={'div'}>
              <p>PO #: {orderDetails.PONumber}</p>
          </Typography>
          <Typography component={'div'}>
              <p>Master #: {orderDetails.MasterNumber}</p>
          </Typography>
        </div>
        <hr style={{border: '1px solid #eeeff1', marginTop:10, marginBottom:10}}/>
        <div className="detail">
          <Typography component={'div'}>
              <p>Ship Date: {orderDetails.RequestedShipDateString}</p>
          </Typography>
          <Typography component={'div'}>
              <p>Ship Method: {orderDetails.ShippingMethod}</p>
          </Typography>        
        </div>
        </div>
        </AccordionDetails>
      </Accordion>   
    </div>
  );
}
export default OrderDetailMobile;