import React, { Component } from 'react';
import logo from '../images/Orenco_Corporate_Logo.svg';
import facebook from '../images/Facebook.svg';
import linkedin from '../images/Linkedin.svg';
import pinterest from '../images/Pinterest.svg';
import twitter from '../images/Twitter.svg';
import youtube from '../images/YouTube.svg';


class Header extends Component {
    render() {
        return (
            <div style={{flex: '0 1 auto'}}>
                <div className="socialBar noPrint">
                    <a href="https://www.facebook.com/orencosystems" target="_blank" rel="noreferrer">
                        <img src={facebook} alt="Facebook" />
                    </a>
                    <a href="https://twitter.com/orenco_systems" target="_blank" rel="noreferrer">
                        <img src={twitter} alt="Twitter" />
                    </a>
                    <a href="https://www.youtube.com/user/OrencoSystems" target="_blank" rel="noreferrer">
                        <img src={youtube} alt="YouTube" />
                    </a>
                    <a href="https://www.linkedin.com/company/1101801/admin/" target="_blank" rel="noreferrer">
                        <img src={linkedin} alt="LinkedIn" />
                    </a>
                    <a href="https://www.pinterest.com/orencosystems/_created/" target="_blank" rel="noreferrer">
                        <img src={pinterest} alt="Pinterest" />
                    </a>
                </div>
                <div className="logo-wrapper">
                    <div className="logo">
                        <a href="/">
                            <img src={logo} alt="Site Logo" />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
export default Header;

