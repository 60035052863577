import React from 'react';
import { IOrderModel } from '../models/IOrder';

const OrderDetailDesktop = ({orderDetails}: {orderDetails: IOrderModel}) => {  

    return (
        <table className="order-table">
            <thead>
                <tr>
                    <th scope="col">Requested Ship Date</th>
                    <th scope="col">Shipping Method</th>
                    <th scope="col">PO #</th>
                    <th scope="col">Project ID</th>
                    <th scope="col">Master #</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{orderDetails.RequestedShipDateString}</td>
                    <td>{orderDetails.ShippingMethod}</td>
                    <td>{orderDetails.PONumber}</td>
                    <td>{orderDetails.ProjectID}</td>
                    <td>{orderDetails.MasterNumber}</td>
                </tr>                
            </tbody>
        </table>
    )
}

export default OrderDetailDesktop;