import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Link, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import BackToTop from '../images/backToTop.svg';
import SortIcon from '../images/sort.svg';
import { IOrdersModel } from "../models/IOrders";
import { IOrdersProps } from './orders';
import SortChooser from './SortChooser';



const OrdersMobile = (props: IOrdersProps) => {
    const history = useHistory();
    const pageSize = 10;
    
    const [pagedOrders, setPagedOrders] = useState<IOrdersModel[]>([]);
    const searchBoxRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const [sortValue, setSortValue] = useState(1);
  
    type Comparator = (a: IOrdersModel, b: IOrdersModel) => number;
    function getComparator(sortId: number): Comparator {

        switch (sortId) {
            case 1: // order date oldest to newest
                return (a, b) => {
                    return new Date(b['OrderDate']).valueOf() - new Date(a['OrderDate']).valueOf();
                };
            case 2: // order date newest to oldest
                return (a, b) => {
                    return new Date(a['OrderDate']).valueOf() - new Date(b['OrderDate']).valueOf();
                };
            case 3: // order number a to z
                return (a, b) => {
                    return a['OrderNumber'].localeCompare(b['OrderNumber']);
                };
            case 4: // order number z to a
                return (a, b) => {
                    return b['OrderNumber'].localeCompare(a['OrderNumber']);
                };
            case 5: // invoice number a to z
                return (a, b) => {
                    return a['InvoiceNumber'].localeCompare(b['InvoiceNumber']);
                };
            case 6: // invoice number z to a
                return (a, b) => {
                    return b['InvoiceNumber'].localeCompare(a['InvoiceNumber']);
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortId}"`);
        }
    }
  
    const handleSortChooserClose = (value) => {
      setOpen(false);
      setSortValue(value);
      props.sortOnChangeHandler(value);
    };

    const handleMoreResults = (event) => {
        setPagedOrders(props.orders.slice(0, pagedOrders.length + pageSize));
    };

    const handleBackToTop = (event) => {
        if (searchBoxRef != null && searchBoxRef.current != null)
        {
            searchBoxRef.current.scrollIntoView()
            const y = searchBoxRef.current.getBoundingClientRect().top + window.pageYOffset - 216;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    };

    const handleOrderNavigation = (event, order: IOrdersModel) => {
        if (order.OrderNumber != null && order.OrderNumber !== '')
            history.push(`/order/${order.OrderNumber}`);
    };

    const handleInvoiceNavigation = (event, order: IOrdersModel) => {
        if (order.InvoiceNumber != null && order.InvoiceNumber !== '')
            history.push(`/invoice/${order.InvoiceNumber}`);
    };

    const sortedOrders = useMemo((): readonly IOrdersModel[] => {
        
        let sortedOrders = [...props.orders];

        sortedOrders.sort((a, b) => {
            
            const comparator = getComparator(sortValue);
            const compResult = comparator(a, b);
            return compResult;
        });
        return sortedOrders;

    }, [props.orders, sortValue]);

    useEffect(() => {
          
        setPagedOrders(sortedOrders.slice(0, pageSize));
      }, [sortedOrders])

    return (
        <div>
            <SortChooser
                selectedValue={sortValue}
                open={open}
                onClose={handleSortChooserClose}
            />
            <Accordion className="accordianOrder">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className="accordianHeader">Orders</Typography>
                </AccordionSummary>
                <AccordionDetails className="orderdetail" ref={searchBoxRef}>
                    <div style={{ alignItems: 'center', justifyContent: 'flex-end', marginBottom: 10, borderBottom: '2px solid #e9e9e9', paddingBottom:20 }}>
                        <span id="lblDateRange" style={{ marginRight: 10 }}>Date Range:</span>
                        <Select
                            id="selDateRange"
                              value={props.dateRange}
                              onChange={props.dateRangeOnChangeHandler}
                        >
                            <MenuItem value={3}>Last 3 months</MenuItem>
                            <MenuItem value={6}>Last 6 months</MenuItem>
                            <MenuItem value={-1}>All</MenuItem>
                        </Select>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <div className="searchbox">
                                <input type="text" id="txtSearch" placeholder="Search" onChange={props.searchOnChangeHandler}></input> <SearchIcon />
                                <div style={{display:'inline-block'}}>
                                    <img className="img-responsive" src={SortIcon} style={{width:20}} alt="Sort" onClick={() => { setOpen(true) }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!props.ordersLoaded ? 
                        <div style={{height:100,width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <CircularProgress/>
                        </div>
                        :
                        <>  
                            {pagedOrders.map((item, i) => {


                                var responseString = "Tracking: ";

                                if (item.ShippingStatus.length > 0)
                                {
                                    responseString = `<span>${item.ShippingStatus}</span>`;
                                }
                                else
                                {
                                    for (var j = 0; j < item.TrackingNumbers.length; j++)
                                    {
                                        var tobj = item.TrackingNumbers[j];

                                        if (tobj.TrackingNumberURL !== null)
                                            responseString += `<a target='_blank' rel='noreferrer' href='${tobj.TrackingNumberURL}'>${tobj.TrackingNumber}</a>`;
                                        else
                                            responseString += `<span>${tobj.TrackingNumber}</span>`;
                                    }
                                }

                                // <div className="shippingStatus">Tracking: {item.TrackingNumberURL.length > 0 ? <a target='_blank' rel="noreferrer" href={`${item.TrackingNumberURL}`}>{item.TrackingNumber}</a>:<span>{item.TrackingNumber}</span>}</div>
                                return (
                                    <div key={item.OrderNumber + item.InvoiceNumber + i}>
                                        <div className="ordercontainer">
                                            <div style={{display:'flex'}}>
                                                <span className="orderDate">PO #: {item.PONumber}</span>
                                                <span className="orderDate">|</span>
                                                <span className="orderDate">{item.OrderDateString}</span>
                                            </div>
                                            <div className="orderInfoContainer">
                                                <div style={{display:'flex'}}>
                                                    <div className="orderbox" onClick={(ev) => { handleOrderNavigation(ev, item) }}>
                                                        <div className="title">Order #</div>
                                                        <div className="mobileOrderValues">{item.OrderNumber}</div>
                                                    </div>
                                                    <div className="invoicebox" onClick={(ev) => { handleInvoiceNavigation(ev, item) }}>
                                                        <div className="title">Invoice #</div>
                                                        <div className="mobileOrderValues">{item.InvoiceNumber}</div>
                                                    </div>
                                                </div>
                                                <div className="orderrate">{item.OrderTotalString}</div>
                                            </div>
                                            <div className="shippingStatus" dangerouslySetInnerHTML={{ __html: responseString }} />
                                        </div>
                                    </div>
                                )
                            })}
                            {pagedOrders.length < props.orders.length 
                            ? 
                                <>
                                
                                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',}}>
                                    <div style={{width:25}}></div>
                                    <Link onClick={handleMoreResults}>{pageSize} more results</Link>
                                    <div>
                                        <img className="img-responsive" src={BackToTop} style={{width:25,float:'right'}} alt="Back to Top" onClick={handleBackToTop}/>
                                    </div>
                                </div>
                                </>
                             : null
                            }
                        </>
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default OrdersMobile;