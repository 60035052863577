import React from 'react';

import { IAccountDetailsModel } from "../models/IAccountDetails";

import '../styles/dashboard.css'
import styles from '../styles/sharedStyles.module.css';

const CustomerServiceTeamInfo = ({accountDetails}: {accountDetails: IAccountDetailsModel}) => {

  return (
    <div style={{marginTop:0,marginBottom:10}}>
      {accountDetails.Contacts.map((item, i) => {
        let href = `mailto:${item.Email}`;
        return (
        <div key={i} className="cardInfo">
          <span className="subHeading">{item.Role}</span>
          <div className={styles.label} style={{fontWeight:'bold'}}>{item.Name}</div>
          <div className={styles.label}>{item.Phone}</div>
          <div className={`${styles.label} ${styles.link}`}><a href={href}>{item.Email}</a></div>
        </div>
        )
      })}
    </div>
  );
}

export default CustomerServiceTeamInfo;