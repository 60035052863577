import React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import { IAccountDetailsModel } from "../models/IAccountDetails";
import CustomerServiceTeamInfo from "./customerServiceTeamInfo";
import AccountInfo from "./accountInfo";

import '../styles/dashboard.css'

const CustomerServiceAccountInfoCardsDesktop = ({accountDetails}: {accountDetails: IAccountDetailsModel}) => {
    
  return (
    <div className="box">
      <Card className="root-left" variant="outlined">
        <CardContent>
          <Typography className="cardTitle">
            Customer Service Team
          </Typography>
          <CustomerServiceTeamInfo accountDetails={accountDetails}/>
        </CardContent>
      </Card>
      <div className="seprater"> <CardContent> </CardContent> </div>
      <Card className="root-right" variant="outlined">
        <CardContent>
          <Typography className="cardTitle">
            Account Information
          </Typography>
          <AccountInfo accountDetails={accountDetails}/>
        </CardContent>
      </Card>
    </div>
  );
}

export default CustomerServiceAccountInfoCardsDesktop;