import { ServiceBase } from "./ServiceBase";
import {Result} from "../models/Result";
import {IOrdersModel} from "../models/IOrders";
import {IOrderModel} from "../models/IOrder";
import {IInvoiceModel} from "../models/IInvoice";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class OrderService extends ServiceBase {

    public static async GetOrders(monthsToGet: number): Promise<Result<IOrdersModel[]>> {

        var result = await this.requestJson<IOrdersModel[]>({
            url: `/Main/GetOrders?monthsToGet=${monthsToGet}`,
            method: "GET"
        });

        return result;
    }

    public static async GetOrder(orderNumber: string): Promise<Result<IOrderModel>> {

        var result = await this.requestJson<IOrderModel>({
            url: `/Main/GetOrder?orderNumber=${orderNumber}`,
            method: "GET"
        });

        return result;
    }

    public static async GetInvoice(invoiceNumber: string): Promise<Result<IInvoiceModel>> {

        var result = await this.requestJson<IInvoiceModel>({
            url: `/Main/GetInvoice?invoiceNumber=${invoiceNumber}`,
            method: "GET"
        });

        return result;
    }

}