import * as React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IAnnouncements } from '../models/IAnnouncements';

const AnnouncementsMobile = ({ announcements }: { announcements: IAnnouncements[] }) => {

    return (
        <div style={{marginTop:50}}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className="accordianHeader">Announcements</Typography>
                </AccordionSummary>
                <AccordionDetails className="orderdetail">

                {announcements.map((item, i) => {
                        return (
                            <div key={`announcement_${i}`}>
                                <div className="announcementsTable" style={{marginBottom:20}}>
                                    <div style={{fontSize: 18, fontWeight:'bold', color:'var(--orenco-gray)'}}>{item.Date}</div>
                                </div>
                                <div className="announcementsTableTitle" style={{textAlign:'left'}}>
                                    {item.Title}
                                </div>
                                <div style={{marginTop:20}}>
                                    <div dangerouslySetInnerHTML={{__html: item.Body}} style={{textAlign:'left'}} />
                                </div>
                                <hr style={{marginTop:20,marginBottom:20}}/>
                            </div>
                        )
                    })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
export default AnnouncementsMobile;