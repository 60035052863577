import { Component } from 'react';
import '../styles/dashboard.css'
import Tealicon from '../images/Teal_icon.svg';
import Navyicon from '../images/Navy_icon.svg';
import Redicon from '../images/Red_icon.svg';
import Grayicon from '../images/Grey_icon.svg';
import MediaQuery from 'react-responsive';

class CategoryBar extends Component {
    render() {

        return (
            <MediaQuery minWidth={1025}>
                <div className="category">
                    <div className="column-odd">
                        <a href="https://www.orenco.com/design-aids" rel="noreferrer" target="_blank" className="categoryLinks">
                            <img className="img-responsive" src={Tealicon} alt="Design Tools" />
                            <h2>Design Tools</h2>
                        </a>
                    </div>
                    <div className="column-even">
                        <a href="https://www.orenco.com/training" rel="noreferrer" target="_blank" className="categoryLinks">
                            <img className="img-responsive" src={Navyicon} alt="Training &amp; Webinars" />
                            <h2>Training</h2>
                        </a>
                    </div>
                    <div className="column-odd">
                        <a href="https://www.orenco.com/resources/document-library" rel="noreferrer" target="_blank" className="categoryLinks">
                            <img className="img-responsive" src={Redicon} alt="Documents" />
                            <h2>Documents</h2>
                        </a>
                    </div>
                    <div className="column-even">
                        <a href="https://www.orenco.com/resources/podcasts" rel="noreferrer" target="_blank" className="categoryLinks">
                            <img className="img-responsive" src={Grayicon} alt="Podcasts" />
                            <h2>Podcasts</h2>
                        </a>
                    </div>
                </div>
            </MediaQuery>
        )
    }
}

export default CategoryBar;
