import { useMsal } from "@azure/msal-react";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from 'react';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import { Link, useHistory } from "react-router-dom";
import ChangeAccountModal from "./Component/ChangeAccountModal";
import { SidebarData } from './Component/sideMenuData';
import UserService from "./services/UserService";

function Navbar({userName, userRole, accountId}) {
  const [sidebar, setSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const showSidebar = () => setSidebar(!sidebar);
  const open = Boolean(anchorEl);
  const { instance } = useMsal();
  const history = useHistory();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccountProfile = () => {
    setAnchorEl(null);
    history.push('/AccountProfile');
  };

  const handleLogout = async() => {
    await UserService.LogoutUser();
    instance.logoutRedirect({postLogoutRedirectUri: "/"})
  }

  return (
    <>
        <div className='navbar noPrint'>
          <Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          {(
            <div className="accountmenu">
              {userName}
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbaaccountmenur"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleAccountProfile} className="menuitem">Account Profile</MenuItem>
                <MenuItem className="menuitem" disabled={true}>AccountID: <span style={{marginLeft:5,fontSize: '90%'}}>{accountId}</span></MenuItem>
                <MenuItem onClick={handleLogout} className="menuitem">Log Out</MenuItem>
              </Menu>
            </div>
          )}
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items'>
            <li className='navbar-toggle' onClick={showSidebar}>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName} onClick={showSidebar}>
                  { item.isRedirect ? (
                    <a href={item.path }>
                      {item.icon}
                      <span>{item.title}</span>
                    </a>
                  ) : (
                    <Link to={item.path }>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  )}
                </li>
              );
            })}
            { (userRole === "SuperAdmin" || userRole === "SuperUser") && //mv change to allow SuperUser
              <ChangeAccountModal userRole={userRole} /> //mv change to limit options/validate if a SuperUser? Or validate backend?
            }
          </ul>        
        </nav>
       
    </>
  );
}

export default Navbar;