import React from 'react';
import { IInvoiceModel } from '../models/IInvoice';

const InvoiceAccountDetailDesktop = ({invoiceAccountDetails}: {invoiceAccountDetails: IInvoiceModel}) => {
    
    return (
        <table className="order-table">
            <thead>
                <tr>
                    <th scope="col">Customer ID</th>
                    <th scope="col">Ordered By</th>
                    <th scope="col">Salesperson ID</th>
                    <th scope="col">Terms</th>
                    <th scope="col">Net Due</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Fax</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{invoiceAccountDetails.CustomerId}</td>
                    <td>{invoiceAccountDetails.OrderedBy}</td>
                    <td>{invoiceAccountDetails.SalesPersonId}</td>
                    <td>{invoiceAccountDetails.Terms}</td>
                    <td>{invoiceAccountDetails.NetDueDateString}</td>
                    <td>{invoiceAccountDetails.AccountPhone}</td>
                    <td>{invoiceAccountDetails.AccountFax}</td>
                </tr>                
            </tbody>
        </table>
    )
}

export default InvoiceAccountDetailDesktop;