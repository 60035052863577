import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import BackIcon from '@mui/icons-material/ArrowBack';
import { useMediaQuery } from 'react-responsive';

import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography
} from "@mui/material";
import { withRouter } from "react-router-dom";
import styles from '../styles/sharedStyles.module.css';

const Breadcrumbs = props => {
  
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  
  const {
    history,
    location: { pathname }
  } = props;
  const pathnames = pathname.split("/").filter(x => x);
  
  if (pathnames.length > 0 && !isDesktopOrLaptop)
  {
    let currentPath = pathnames[0].toLowerCase();
    if (currentPath === 'order' || currentPath === 'invoice' || currentPath === 'accountprofile' || currentPath === 'announcements')
      return (<Link onClick={() => history.push("/")} style={{display: 'flex', alignItems: 'center', cursor:'pointer'}}><BackIcon className={styles.icon} /> Back</Link>);
  }
  else
    return (
    <MUIBreadcrumbs aria-label="breadcrumb" className='noPrint'>
      {pathnames.length > 0 ? (
        <Link onClick={() => history.push("/")} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor:'pointer'}}><HomeIcon className={styles.icon} /> Home</Link>
      ) : (
        <div style={{display: 'flex', alignItems: 'center'}}><HomeIcon key="bc1" className={styles.icon} /><Typography key="bc2">  Home </Typography></div>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        if (name.toLowerCase() === "accountprofile")
          name = "Account Profile";

        return isLast || name === 'order' || name === 'invoice'? (
              <Typography key={name}>{name[0].toUpperCase() + name.substring(1)}</Typography>
        ) : (
          <Link key={name} onClick={() => history.push(routeTo)}>
            {name[0].toUpperCase() + name.substring(1)}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
