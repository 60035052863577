import * as React from 'react';
import {Typography, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { IAccountDetailsModel } from "../models/IAccountDetails";
import CustomerServiceTeamInfo from "./customerServiceTeamInfo";
import AccountInfo from "./accountInfo";

const CustomerServiceAccountInfoCardsMobile = ({accountDetails}: {accountDetails: IAccountDetailsModel}) => {
  
  return (
    <div>
      <Accordion className="accordianCard">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="accordianHeader">Customer Service Team</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomerServiceTeamInfo accountDetails={accountDetails}/>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordianCard">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="accordianHeader">Account Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AccountInfo accountDetails={accountDetails}/>
        </AccordionDetails>
      </Accordion>     
    </div>
  );
}

export default CustomerServiceAccountInfoCardsMobile;