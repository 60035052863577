import * as React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IInvoiceModel } from '../models/IInvoice';



const InvoiceDetailMobile = ({invoiceDetails}: {invoiceDetails: IInvoiceModel}) => {  
  return (
    <div>
      <Accordion className="accordianCard">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="accordianHeader">Order Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="cardInfo" style={{marginBottom:10}}>
            <div className="subHeading">Order #: {invoiceDetails.OrderNumber}</div>
          </div>
          <div>
            <div className="detail">
              <Typography component={'div'}>
                  <p>Project Id: {invoiceDetails.ProjectID}</p>
              </Typography>
              <Typography component={'div'}>
                  <p>Po #: {invoiceDetails.PONumber}</p>
              </Typography>
              <Typography component={'div'}>
                  <p>Master #: {invoiceDetails.MasterNumber}</p>
              </Typography>
            </div>
            <hr style={{border: '1px solid #eeeff1', marginTop:10, marginBottom:10}}/>
            <div className="detail">
              <Typography component={'div'}>
                  <p>Ship Date: {invoiceDetails.ShipDateString}</p>
              </Typography>
              <Typography component={'div'}>
                  <p>Tracking Number: {invoiceDetails.TrackingNumberURL.length > 0 ? <a target='_blank' rel="noreferrer" href={`${invoiceDetails.TrackingNumberURL}`}>{invoiceDetails.TrackingNumber}</a>:<span>{invoiceDetails.TrackingNumber}</span>}</p>
              </Typography>
              <Typography component={'div'}>
                  <p>Ship Method: {invoiceDetails.ShippingMethod}</p>
              </Typography>        
            </div>
          </div>
        </AccordionDetails>
      </Accordion>   
    </div>
  );
}
export default InvoiceDetailMobile;