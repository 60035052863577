import { useCallback, useEffect, useState } from "react";
import { CardContent, Typography, Card } from "@mui/material";
import MediaQuery from 'react-responsive';
import { useParams, RouteComponentProps } from "react-router-dom";

import OrderService from '../services/OrderService';
import { IOrderModel} from '../models/IOrder';
import '../styles/invoice-order.css';
import ProductsOrderedDesktop from "./productsOrderedDesktop";
import InvoiceDetailDesktop from "./invoiceDetailDesktop";
import InvoiceDetailMobile from "./invoiceDetailMobile";
import InvoiceAccountDetailDesktop from "./invoiceAccountDetailDesktop";
import InvoiceAccountDetailMobile from "./invoiceAccountDetailMobile";
import { CircularProgress } from "@mui/material";

import logo from '../images/Orenco_Corporate_Logo.svg';

import PrintIcon from '@mui/icons-material/Print';
import { IInvoiceModel } from "../models/IInvoice";


export interface IOrderProductProps {
    order: IOrderModel,
    orderdetailLoaded: boolean,
}

export default function Invoice(props: RouteComponentProps<{
    invoiceNumber: string;
}>) {
    
    let { invoiceNumber } = useParams<{ invoiceNumber: any }>();

    const [invoiceLoaded, setInvoiceLoaded] = useState<boolean>(false);
    const [invoice, setInvoice] = useState<IInvoiceModel>({} as IInvoiceModel);


    const fetchInvoice = useCallback(async () => {

        var invoiceResponse = await OrderService.GetInvoice(invoiceNumber);
        if (invoiceResponse.success && invoiceResponse.value != null) {
            
            setInvoice(invoiceResponse.value);
            setInvoiceLoaded(true);
        }
        else {
            alert('An error occurred while loading the invoice');
        }

    }, [invoiceNumber]);

    useEffect(() => {

        fetchInvoice()
    }, [fetchInvoice])

    const handlePrint = () => {
        window.print();
    };

    return (
        <>
            {!invoiceLoaded ?
                <div className="orderLoading" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
                :
                <><div className="modal-body order-body">
                        <Card variant="outlined">
                            <CardContent>
                                <MediaQuery minWidth={1025}>
                                    <Typography className="order-title-container printOnly" component={'div'}>
                                        <div className="order-container-logo">
                                            <a href="/">
                                                <img src={logo} alt="Site Logo" className="order-top-logo" />
                                            </a>
                                        </div>
                                        <span className="title">Invoice</span>
                                    </Typography>
                                    <Typography component={'div'}>
                                        <div className="subtitle-container">
                                            <div className="subtitle"><span className="bold">Date:&nbsp;</span> {invoice?.InvoiceDateString}</div>
                                            <div className="subtitle"><span className="bold">Invoice:&nbsp;</span> {invoice?.InvoiceNumber}</div>
                                        </div>
                                    </Typography>
                                </MediaQuery>
                                <MediaQuery maxWidth={1024}>
                                    <div className="subtitle-container">
                                        <div className="subtitle-text"><h4>Date:&nbsp;</h4><h4>{invoice?.InvoiceDateString}</h4></div>
                                        <div className="subtitle-text"><h3>Invoice:&nbsp;</h3><h3>{invoice?.InvoiceNumber}</h3></div>
                                    </div>
                                </MediaQuery>
                                <Typography component={'div'}>
                                    <div className="info-container">
                                        <div className="info">
                                            <span className="bold">Bill To:</span>
                                            <span className="cname">{invoice.BillingCompanyName}</span>
                                            <span>{invoice.BillingAddress?.Street1}</span>
                                            <span>{invoice.BillingAddress?.Street2}</span>
                                            <span>{invoice.BillingAddress?.City}, {invoice.BillingAddress?.State}, {invoice.BillingAddress?.ZipCode}</span>
                                            <span>{invoice.BillingAddress?.Country}</span>
                                        </div>
                                        <hr style={{border: '1px solid #eeeff1', marginTop:10, marginBottom:10}}/>
                                        <div className="info">
                                            <span className="bold">Ship To:</span>
                                            <span className="cname">{invoice.ShippingCompanyName}</span>
                                            <span>{invoice.ShippingAddress?.Street1}</span>
                                            <span>{invoice.ShippingAddress?.Street2}</span>
                                            <span>{invoice.ShippingAddress?.City}, {invoice.ShippingAddress?.State}, {invoice.ShippingAddress?.ZipCode}</span>
                                            <span>{invoice.ShippingAddress?.Country}</span>
                                        </div>
                                    </div>
                                </Typography>
                                <MediaQuery minWidth={1025}>
                                    <div className="table-container">
                                        <span className="bold">Account Details</span>
                                    </div>
                                    {invoiceLoaded && <InvoiceAccountDetailDesktop invoiceAccountDetails={invoice} />}
                                    <div className="table-container">
                                        <span className="bold">Order Details</span>
                                    </div>
                                    {invoiceLoaded && <InvoiceDetailDesktop invoiceDetails={invoice} />}
                                    <div className="table-container">
                                        <span className="bold">Products Ordered</span>
                                    </div>
                                    {invoiceLoaded && <ProductsOrderedDesktop item={invoice} />}
                                </MediaQuery>
                                <MediaQuery maxWidth={1024}>
                                    <div className="acctdetail" style={{ marginTop: 20 }}>
                                        <InvoiceAccountDetailMobile invoiceAccountDetails={invoice} />
                                    </div>
                                    <div className="acctdetail">
                                        <InvoiceDetailMobile invoiceDetails={invoice} />
                                    </div>
                                    <Typography component={'div'}>
                                        <div className="product-container">
                                            {invoice.OrderDetails && invoice.OrderDetails.map((item, i) => {
                                                return (
                                                    <div key={"orderDetails" + i.toString()} style={{ borderBottom: '1px solid #37414e' }}>
                                                        <div className="description-container">
                                                            <div className="info">
                                                                <span className="bold blue">Description:</span>
                                                                <span className="semi-bold">Item # {item.ItemNumber} | QTY {item.Quantity}</span>
                                                                <span className="product">{item.Description}</span>
                                                            </div>
                                                            <div className="info" style={{ alignItems: 'flex-end', marginRight:0 }}>
                                                                <span className="bold blue">U/M</span>
                                                                <span className="semi-bold">{item.UM}</span>
                                                                <span className="semi-bold">{item.PriceString}</span>
                                                            </div>
                                                        </div><span className="bold blue total">Line Total: {item.TotalString}</span>
                                                    </div>
                                                )
                                            })}
                                            <div className="bill-container">
                                                <div className="info" style={{ marginRight: 10, width:140 }}>
                                                    <span className="data">Subtotal</span>
                                                    <span className="data">Tax</span>
                                                    <span className="data">S & H</span>
                                                    <span className="data">Trade Discount</span>
                                                </div>
                                                <div className="info" style={{ alignItems: 'flex-end'}}>
                                                    <span className="value">{invoice.SubtotalString}</span>
                                                    <span className="value">{invoice.TaxTotalString}</span>
                                                    <span className="value">{invoice.ShippingAndHandlingTotalString}</span>
                                                    <span className="value">{invoice.TradeDiscountTotalString}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total-bill-container">
                                            <div className="total-container">
                                                <div className="info" style={{ marginRight: 10, width:140 }}>
                                                    <span className="value">Total USD</span>
                                                </div>
                                                <div className="info" style={{ alignItems: 'flex-end'}}>
                                                    <span className="value">{invoice.TotalString}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Typography>
                                </MediaQuery>
                                <MediaQuery minWidth={1025}>
                                    <div className="buttons">
                                        <div className="print noPrint" style={{width:100}} onClick={handlePrint}><PrintIcon/>PRINT</div>
                                    </div>
                                </MediaQuery>
                            </CardContent>
                        </Card>
                    </div></>
            }
        </>
    );
}