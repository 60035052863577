import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, Link, MenuItem, Paper, Select, TableContainer } from "@mui/material";
import React, { useMemo, useState } from 'react';
import DataGrid, { SortColumn } from 'react-data-grid';
import { useHistory } from "react-router-dom";
import { IOrdersModel } from "../models/IOrders";
import { IOrdersProps } from './orders';

type Comparator = (a: IOrdersModel, b: IOrdersModel) => number;
function getComparator(sortColumn: string): Comparator {

    switch (sortColumn) {
        case 'OrderNumber':
        case 'PONumber':
        case 'InvoiceNumber':
        case 'ShippingMethod':
        case 'ShippingStatus':
            return (a, b) => {
                return a[sortColumn].localeCompare(b[sortColumn]);
            };
        case 'OrderTotalString':
            return (a, b) => {
                var aOrderTotal = (a['OrderTotal'] == null ? 0 : a['OrderTotal']);
                var bOrderTotal = (b['OrderTotal'] == null ? 0 : b['OrderTotal']);
                return aOrderTotal - bOrderTotal;
            };
        case 'OrderDateString':
            return (a, b) => {
                return new Date(a['OrderDate']).valueOf() - new Date(b['OrderDate']).valueOf();
            };
        default:
            throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
}

const OrdersDesktop = (props: IOrdersProps) => {    
    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const history = useHistory();
    const sortedOrders = useMemo((): readonly IOrdersModel[] => {
        if (sortColumns.length === 0)
            return props.orders;

        let sortedOrders = [...props.orders];

        sortedOrders.sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
        return sortedOrders;
    }, [props.orders, sortColumns]);

    const trackingNumberFormatter = (columnData: any) => {
        
        var responseString = "";

        if (columnData.row.ShippingStatus.length > 0)
        {
            responseString = `<span>${columnData.row.ShippingStatus}</span>`;
        }
        else
        {
            for (var i = 0; i < columnData.row.TrackingNumbers.length; i++)
            {
                var tobj = columnData.row.TrackingNumbers[i];

                if (tobj.TrackingNumberURL !== null)
                    responseString += `<a target='_blank' rel='noreferrer' href='${tobj.TrackingNumberURL}'>${tobj.TrackingNumber}</a>`;
                else
                    responseString += `<span>${tobj.TrackingNumber}</span>`;
            }
        }

        return <div dangerouslySetInnerHTML={{ __html: responseString }} />;
    }

    const columns = [        
        { key: 'OrderDateString', name: 'Order Date' },
        {
            key: 'OrderNumber', name: 'Order #', formatter: ({ row }) => <Link onClick={() => { history.push(`/order/${row.OrderNumber}`) }}>{row.OrderNumber}</Link>       
        },
        { key: 'PONumber', name: 'PO #' },
        {
            key: 'OrderTotalString',
            name: 'Order Total'
        },
        { key: 'ShippingMethod', name: 'Shipping Method' },
        { 
            key: 'ShippingStatus', 
            name: 'Tracking #',
            width: 170,
            style: { 'white-space': 'normal' }, //Add this line to the column definition
            formatter: trackingNumberFormatter
        },
        { 
            key: 'InvoiceNumber', name: 'Invoice #', formatter: ({ row }) => <Link onClick={() => { history.push(`/invoice/${row.InvoiceNumber}`) }}>{row.InvoiceNumber}</Link>       
        }
    ];

    const rowKeyGetter = (row: IOrdersModel) => {
        return row.OrderNumber + row.InvoiceNumber;
    };

    const rowHeightSetter = (rowHeightArgs: any) => {
        
        var defaultHeight = 45;
        if (rowHeightArgs.row.TrackingNumbers !== null && rowHeightArgs.row.TrackingNumbers.length > 1) {
            return rowHeightArgs.row.TrackingNumbers.length * 25;
        }
        else
            return defaultHeight;
    };

    return (
        <div className="ordertable">
            <p className="ordertitle cardTitle">Orders</p>
            <TableContainer component={Paper}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 10 }}>
                    <span id="lblDateRange" style={{ marginRight: 10 }}>Date Range:</span>
                    <Select
                        id="selDateRange"
                        value={props.dateRange}
                        onChange={props.dateRangeOnChangeHandler}
                    >
                        <MenuItem value={3}>Last 3 months</MenuItem>
                        <MenuItem value={6}>Last 6 months</MenuItem>
                        <MenuItem value={-1}>All</MenuItem>
                    </Select>
                    <div className="searchbox">
                        <input type="text" id="txtSearch" placeholder="Search" onChange={props.searchOnChangeHandler}></input> <SearchIcon />
                    </div>
                </div>
                {!props.ordersLoaded ?
                    <div style={{ height: 350, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <DataGrid
                            className={'ordersTable'}
                            columns={columns}
                            rows={sortedOrders}
                            rowKeyGetter={rowKeyGetter}
                            rowHeight={rowHeightSetter}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true
                            }}
                            sortColumns={sortColumns}
                            onSortColumnsChange={setSortColumns}                           
                        />
                    </>
                }
            </TableContainer>
        </div>
    );
}

export default OrdersDesktop;